import React from "react";

const ETHDiamondString = `
                                                 ~.                                                 
                                                73:.                                                
                                              .J5J::.                                               
                                             ^333J:.::                                              
                                            !5333J:.:::.                                            
                                          .J53333J:.::::.                                           
                                         :3533333J:.:::::.                                          
                                        ~53333333J:.:::::::.                                        
                                       ?533333333J:.::::::::.                                       
                                     .J5333333333J:.:::::::::.                                      
                                    ^333333333333J:.:::::::::::                                     
                                   75333333333333J:.::::::::::::.                                   
                                 .J53333333333333J:.:::::::::::::.                                  
                                :3333333333333333J:.::::::::::::::.                                 
                               !53333333333333333J:.::::::::::::::::.                               
                             .?533333333333333333J:.:::::::::::::::::.                              
                            :35333333333333333333J:.::::::::::::::::::.                             
                           ~333333333333333333333J:.::::::::::::::::::::.                           
                          75333333333333333333333J:.:::::::::::::::::::::.                          
                        .J53333333333333333333333J:.::::::::::::::::::::::.                         
                       ^3333333333333333333333333J:.:::::::::::::::::::::::.                        
                      !53333333333333333333333333J:.:::::::::::::::::::::::::.                      
                    .?533333333333333333333333333J:.::::::::::::::::::::::::::.                     
                   :35333333333333333333333333333J:.:::::::::::::::::::::::::::.                    
                  ~533333333333333333333333333333J:.:::::::::::::::::::::::::::::.                  
                 75333333333333333333333333333333J:.::::::::::::::::::::::::::::::.                 
               .J53333333333333333333333333333333J:.:::::::::::::::::::::::::::::::.                
              ^3333333333333333333333333333333335J:::::::::::::::::::::::::::::::::::               
             !53333333333333333333333333333333J7!^.....:::::::::::::::::::::::::::::::.             
           .J5333333333333333333333333533J?!~^::::     .....:::::::::::::::::::::::::::.            
          :3533333333333333333333333J?!~^:::::::::          ....::::::::::::::::::::::::.           
         ~5333333333333333333333?7~^::::::::::::::               ....:::::::::::::::::::::.         
        ?533333333333333533J7!^:::::::::::::::::::                    ....:::::::::::::::::.        
      .J53333333333333J?!~^:::::::::::::::::::::::                        .....:::::::::::::.       
     ^333333333333?7~^::::::::::::::::::::::::::::                             ....:::::::::::      
    753333333J7~^:::::::::::::::::::::::::::::::::                                  ....:::::::.    
  .J5533J?!~^:::::::::::::::::::::::::::::::::::::                                      .....:::.   
 ^33?!~^::::::::::::::::::::::::::::::::::::::::::                                           .....  
:~^:::::::::::::::::::::::::::::::::::::::::::::::                                                . 
   ..:::::::::::::::::::::::::::::::::::::::::::::                                                  
      ..::::::::::::::::::::::::::::::::::::::::::                                                  
          ..::::::::::::::::::::::::::::::::::::::                                                  
              .:::::::::::::::::::::::::::::::::::                                                  
.:.              ..:::::::::::::::::::::::::::::::                                                  
 .7J!.               ..:::::::::::::::::::::::::::                                             .:.  
   :3537^.               .::::::::::::::::::::::::                                         ..:::    
     !5553J!:               ..::::::::::::::::::::                                      .:::::.     
      :J533553?~.               ..::::::::::::::::                                  ..:::::::       
        !5333335537^.              ..:::::::::::::                              ..:::::::::.        
         :J5333333553J!:               ..:::::::::                           .::::::::::::          
           !3333333333553?~.               .::::::                       ..:::::::::::::.           
            .J53333333333355J7^.              ..::                   ..::::::::::::::::             
              ~33333333333333553?~:                               .::::::::::::::::::.              
               .J53333333333333335537^.                       ..::::::::::::::::::::                
                 ~333333333333333333553J!:                 .::::::::::::::::::::::.                 
                  .?53333333333333333333553?~.         ..:::::::::::::::::::::::.                   
                    ~353333333333333333333335537^. ..::::::::::::::::::::::::::.                    
                     .?533333333333333333333333553:::::::::::::::::::::::::::.                      
                       ~35333333333333333333333333::::::::::::::::::::::::::.                       
                        .?533333333333333333333333::::::::::::::::::::::::.                         
                          ^35333333333333333333333:::::::::::::::::::::::.                          
                           .?533333333333333333333:::::::::::::::::::::.                            
                             ^35333333333333333333::::::::::::::::::::.                             
                              .7533333333333333333::::::::::::::::::.                               
                                ^35333333333333333:::::::::::::::::.                                
                                  7533333333333333:::::::::::::::.                                  
                                   :35333333333333::::::::::::::                                    
                                     7533333333333::::::::::::.                                     
                                      :35333333333:::::::::::                                       
                                        !533333333:::::::::.                                        
                                         :J5333333::::::::                                          
                                           !533333::::::.                                           
                                            :J5333:::::                                             
                                              !553:::.                                              
                                               .J3^:                                                
                                                 !.                                                 
`;
const EthDiamond = ({ className, isPrimaryColor, smallScreen }) => {
  return (
    <div
      className={`whitespace-pre font-mono text-[4px] xl:text-[6px] tracking-wider ${className}`}
      style={{
        color: isPrimaryColor
          ? "rgba(255, 210, 0, 0.5)"
          : "rgba(158, 141, 226, 0.75)",
        marginTop: !smallScreen && "60vh",
      }}
    >
      {ETHDiamondString}
    </div>
  );
};

EthDiamond.defaultProps = {
  isPrimaryColor: true,
};
export default EthDiamond;
